<template>
    <div class="title-section">
        <ViewTitle title="後台設定 | 商品批次管理" class="detail-viewtitle" />
    </div>
    <div>
        <div class="subtitle-section">
            <p class="second-title">檔案上傳區</p>
            <div class="update-status">
                <p>點擊後取得上傳/套用最新狀態</p>
                <Button
                    :width="108"
                    buttonWord="更新狀態"
                    buttonSize="default"
                    :buttonStyle="'blue'"
                    :disabled="!getInfosSuccess"
                    @click="getFilesInfo"
                />
            </div>
        </div>
        <div v-if="productInfos.length === 0" class="upload-group no-data">
            <div v-if="isInfoLoading" class="process-loading-icon"></div>
            <DashboardErrorMessage v-else @reacquire="getFilesInfo" />
        </div>
        <template v-else>
            <div
                v-for="(info, index) in productInfos"
                :key="index"
                class="upload-group"
            >
                <div v-if="'appliedAt' in info" class="update-container">
                    <div class="update-info">
                        <p class="applied-text">
                            {{
                                info.isApplying
                                    ? '套用規則中'
                                    : canApplyRule(info, index)
                                    ? '請更新規則'
                                    : '已套用規則'
                            }}
                        </p>
                        <p class="applied-time">
                            最後更新時間：{{ info.appliedAt ?? '-' }}
                        </p>
                    </div>

                    <Button
                        v-if="!info.isApplying"
                        :width="108"
                        buttonWord="套用規則"
                        buttonSize="default"
                        :buttonStyle="
                            canApplyRule(info, index) ? 'blue' : 'grey'
                        "
                        :disabled="!canApplyRule(info, index)"
                        @click="updateProducts()"
                    />
                    <Button
                        v-else
                        buttonWord="套用中..."
                        buttonSize="default"
                        buttonStyle="hollow"
                        class="download-loading disabled"
                    />
                </div>

                <ProductFiles
                    v-for="(product, productIndex) in info.items"
                    :key="productIndex"
                    :title="product.title"
                    :customType="product.customType"
                    :updatedAt="product.updatedAt"
                    :indexKey="productIndex"
                    :isInProgress="product.isInProgress"
                    :isApplying="info.isApplying ?? false"
                    :importResult="product.importResult"
                    @reload="getFilesInfo"
                />
            </div>
        </template>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import ProductFiles from '@/containers/backstage/ProductFiles.vue'
import DashboardErrorMessage from '@/containers/home/DashboardErrorMessage.vue'
import {
    getBackstageProductFilesInfoAPI,
    postBackstageUpdateProductsAPI
} from '@/assets/javascripts/api'

export default {
    name: 'BackstageProduct',
    components: {
        Button,
        ViewTitle,
        ProductFiles,
        DashboardErrorMessage
    },
    computed: {
        lastUpdateTimes: function () {
            const infos = Object.values(this.productInfos)
            const lastTimeList = infos.reduce((memo, info, index) => {
                const items = [...info.items]
                memo[`${index}`] = items
                    .map((item) => {
                        return item?.updatedAt ?? ''
                    })
                    .sort()
                    .at(-1)
                return memo
            }, {})
            return lastTimeList
        },
        /**
         * 是否有商品正在上傳中
         */
        isUploading: function () {
            const infos = Object.values(this.productInfos)
            return infos.some((info) => {
                return info.items.some((item) => item.isInProgress)
            })
        }
    },
    methods: {
        getFilesInfo: async function () {
            this.$showLoading()
            this.isInfoLoading = true
            try {
                const res = await getBackstageProductFilesInfoAPI()
                const data = res.data.productFileInfos
                this.productInfos = data
                this.getInfosSuccess = true
            } catch {
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
                this.productInfos = []
                this.getInfosSuccess = false
            } finally {
                this.isInfoLoading = false
                this.$hideLoading()
            }
        },
        updateProducts: async function () {
            this.$setGaEvent(`applyProductRule`, 'click-button')
            try {
                await postBackstageUpdateProductsAPI()
            } catch {
                this.$showErrorMessage(
                    '更新時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                await this.getFilesInfo()
            }
        },
        isUpdate: function (applyDate, infoList) {
            if (!applyDate || !infoList) return false
            const dates = infoList.map((item) => item.updatedAt)
            const isApply = dates.some((updatedAt) => {
                updatedAt < applyDate
            })

            return isApply
        },
        /**
         * 是否可套用規則
         * @param info productFileInfos item
         * @param index productFileInfos index
         * @returns Boolean
         * @description
         * 最後套用時間 < 所有商品中最後上傳時間, 非套用中, 非上傳中才可套用
         */
        canApplyRule: function (info, index) {
            const applyDateIsEarlier =
                new Date(info.appliedAt) < new Date(this.lastUpdateTimes[index])
            return applyDateIsEarlier && !info.isApplying && !this.isUploading
        }
    },
    watch: {},
    data() {
        return {
            productInfos: [],
            isInfoLoading: false,
            getInfosSuccess: true
        }
    },
    async created() {
        await this.getFilesInfo()
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}

.title-section {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.process-loading-icon {
    margin: 40px auto;
    width: 30px;
    height: 30px;
}

.subtitle-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: auto;
    .second-title {
        color: $sixth-black;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        padding: 7px 0;
        @media screen and (max-width: 576px) {
            margin-bottom: 10px;
        }
    }

    .update-status {
        display: flex;
        align-items: center;

        p {
            margin-right: 10px;
            width: fit-content;
            color: $sixth-black;
            font-size: 12px;
        }
    }
}
.upload-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: $primary-white;
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 20px;
    min-height: 150px;
    @media screen and (max-width: 576px) {
        padding: 15px;
    }
    &.no-data {
        padding-top: 50px;
    }
}

.update-container {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 576px) {
        display: block;
    }
    .update-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-right: 10px;
        @media screen and (max-width: 576px) {
            display: block;
            margin-bottom: 1rem;
        }
        .applied-text {
            width: fit-content;
            color: $sixth-black;
            font-size: 14px;
        }
        .applied-time {
            color: $sixth-black;
            font-size: 12px;
            font-weight: 500;
        }
    }
}
</style>
